<template>
	<div class="content__form">
		<s-dialog-success v-model="showSuccess">
			<h3>{{ll.i6831 || 'Thank you!'}}</h3>
			<p>{{ll.i6832 || 'Your request has been sent successfully'}}</p>
		</s-dialog-success>
		<h2>{{ll.i6827}}</h2>
		<h3>{{ll.i6828}}</h3>
		<s-group class="form-group"  :has-error="nameError" :error-text="ll.i6836 || 'Name is required'">
			<s-input id="name" v-model="name" :disableInputMobile="true"/>
			<label v-if="!name" for="name" v-html="ll.i6833 || 'Name<span>*</span>'"></label>
		</s-group>
		<br>
		<s-group class="form-group" :has-error="emailError" :error-text="ll.i6837 || '* Enter correct e-mail'">
			<s-input id="email" type="email" v-model="email" :disableInputMobile="true"/>
			<label v-if="!email" for="email" v-html="ll.i6834 || 'Email<span>*</span>'"></label>
		</s-group>
		<br>
		<s-group class="form-group" :has-error="phoneError" :error-text="ll.i6838 || 'Please, enter the correct website URL'">
			<!--<s-input id="phone" type="text" v-model="phone" :disableInputMobile="true"/>
			<label v-if="!phone" for="siteUrl" v-html="ll.i6835 || 'Phone<span>*</span>'"></label>-->
			<s-input-tel id="phone" type="" v-model="phone" autocomplete="off" /> 
		</s-group>

		<button class="form-group--button-reseller" @click="setData()">{{ll.i6839 || 'Request details'}}</button>
		<p class="privacy-terms-of-service"><!--
			By entering your data you agree to <a class="underline" href="/terms-of-service" target="_blank">Terms of Service</a>
			<span> and </span><a class="underline" href="/privacy-policy" target="_blank">Privacy Policy</a>-->
		</p>
		<div class="content-form__underline"></div>
		<div class="content__form--form-footer">
			<img src="/ims/like-for-form.png" alt="">
			<p><span class="text-type4">{{ll.i6840||'Trusted by'}} <span class="text-type5">{{ll.i6841||'100+'}}</span></span> {{ll.i6842||'website owners'}}</p>
			<div class="content__form--form-footer__underline"></div>
		</div>
	</div>
</template>
<script>
	/* eslint-disable */
	import axios from 'axios';
	export default {
		name: "Form",
		data(){
			return{
				id: 0,
				name: '',
				nameError: false,
				nameTextError: '',
				email: '',
				emailError: false,
				emailTextError: '',
				siteUrl: '',
				siteUrlError: false,
				phone: '+1',
				phoneError: false,
				phoneTextError: '',
				managerName: '',
				errors: [],
				isRegister: false,
				lastAttempt: false,
				showSuccess: false,
				idUserSemalt: 0
			}
		},
		props: {
			ll: Object
		},
		methods: {
			async setData(){
				this.validateInputName()
				this.validateInputEmail()
				//this.validateInputSite()
				await this.validateInputPhone()
				if(!this.phoneError && !this.emailError && !this.nameError){
					this.sendRequestSemalt();
					/*
					let config = {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					};
					axios.post('https://semalt.com/ajax/reg/register.php', {
						q: 'register',
						subject: 'Backlink for SEO Agencies: ',
						email: this.email,
						username: this.name,
						domain: this.siteUrl.toLowerCase().trim(),
						statfilter:494,
						controller: location.href,
						referrer: document.referrer
					}, config)
						.then(response => {
							if(response.status ==200 && response.data){
								try {
									if(response.data.status=="OK" && response.data.id){
										this.idUserSemalt = response.data.id;
										this.sendRequestSemalt();
									}
									else if(response.data.status=="ERROR" && response.data.msg && response.data.msg=='USER_EXIST' && response.data.user){
										this.idUserSemalt = response.data.user;
										this.sendRequestSemalt();
									}
								}catch(e){}
							}
						})
						.catch(e => {
							this.errors.push(e)
						})
					*/
					this.lastAttempt = false
				}
				else{
					this.lastAttempt = true
				}
			},
			async sendRequestSemalt(){
			    let data = {
			        page:location.href,
                    name:this.name,
					email:this.email,
					phone: this.phone
				};
                let res = await axios.post('/api/support/request', data);
                if(res && res.data){
                    //this.$refs.snotify.text(this.ll.i6666 || "Your message has been sent successfully",3000);
                    this.name = ''
                    this.email = ''
                    //this.phone = ''
                    this.showSuccess = true;
                    console.log(res.data);
                }

			    /*let config = {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				};
				axios.post('https://semalt.com/ajax/support/send_request.php', {
					q: 'seo_cosultation_header',
					idu: this.idUserSemalt,
					name: this.name,
					email: this.email,
					phone:this.phone,
					url: '', //this.siteUrl.toLowerCase().trim(),
					ref:'BacklinksAgencies'
				}, config)
					.then(response => {
						this.name = ''
						this.email = ''
						//this.phone = ''
						console.log(response);
					})
					.catch(e => {
						this.errors.push(e)
					});*/
			},
			validateInputName() {
				this.nameError = false
				const res = this.$frontend.validate.empty(this.name)
				if (res === true) {
					this.nameError = true
					this.nameTextError = res
				}
			},
			validateInputEmail() {
				this.emailError = false
				const res = this.$frontend.validate.email(this.email)
				if (res !== true) {
					this.emailError = true
					this.emailTextError = res
				}
			},
			validateInputSite() {
				const res = this.$frontend.validate.website(this.siteUrl.toLowerCase().trim());
				if (res !== true) {
					this.siteUrlError = true
				} else {
					this.siteUrlError = false
				}
			},
			async validateInputPhone() {
                this.phoneError = false
				if(this.phone.length>5){
                    const res = await this.$frontend.validate.phone(this.phone)
                    if (res !== true) {
                        this.phoneError = true
                        this.phoneTextError = res
                    }
				}
			},
			geoPhoneMap() {
				return {"AF":"93","AL":"355","DZ":"213","AS":"1-684","AD":"376","AO":"244","AI":"1-264","AQ":"672",
					"AG":"1-268","AR":"54","AM":"374","AW":"297","AU":"61","AT":"43","AZ":"994","BS":"1-242","BH":"973",
					"BD":"880","BB":"1-246","BY":"375","BE":"32","BZ":"501","BJ":"229","BM":"1-441","BT":"975","BO":"591",
					"BA":"387","BW":"267","BR":"55","IO":"246","VG":"1-284","BN":"673","BG":"359","BF":"226","BI":"257",
					"KH":"855","CM":"237","CA":"1","CV":"238","KY":"1-345","CF":"236","TD":"235","CL":"56","CN":"86",
					"CX":"61","CC":"61","CO":"57","KM":"269","CK":"682","CR":"506","HR":"385","CU":"53","CW":"599",
					"CY":"357","CZ":"420","CD":"243","DK":"45","DJ":"253","DM":"1-767","DO":"1-809, 1-829, 1-849",
					"TL":"670","EC":"593","EG":"20","SV":"503","GQ":"240","ER":"291","EE":"372","ET":"251","FK":"500",
					"FO":"298","FJ":"679","FI":"358","FR":"33","PF":"689","GA":"241","GM":"220","GE":"995","DE":"49",
					"GH":"233","GI":"350","GR":"30","GL":"299","GD":"1-473","GU":"1-671","GT":"502","GG":"44-1481",
					"GN":"224","GW":"245","GY":"592","HT":"509","HN":"504","HK":"852","HU":"36","IS":"354","IN":"91",
					"ID":"62","IR":"98","IQ":"964","IE":"353","IM":"44-1624","IL":"972","IT":"39","CI":"225","JM":"1-876",
					"JP":"81","JE":"44-1534","JO":"962","KZ":"7","KE":"254","KI":"686","XK":"383","KW":"965","KG":"996",
					"LA":"856","LV":"371","LB":"961","LS":"266","LR":"231","LY":"218","LI":"423","LT":"370","LU":"352",
					"MO":"853","MK":"389","MG":"261","MW":"265","MY":"60","MV":"960","ML":"223","MT":"356","MH":"692",
					"MR":"222","MU":"230","YT":"262","MX":"52","FM":"691","MD":"373","MC":"377","MN":"976","ME":"382",
					"MS":"1-664","MA":"212","MZ":"258","MM":"95","NA":"264","NR":"674","NP":"977","NL":"31","AN":"599",
					"NC":"687","NZ":"64","NI":"505","NE":"227","NG":"234","NU":"683","KP":"850","MP":"1-670","NO":"47",
					"OM":"968","PK":"92","PW":"680","PS":"970","PA":"507","PG":"675","PY":"595","PE":"51","PH":"63",
					"PN":"64","PL":"48","PT":"351","PR":"1-787, 1-939","QA":"974","CG":"242","RE":"262","RO":"40",
					"RU":"7","RW":"250","BL":"590","SH":"290","KN":"1-869","LC":"1-758","MF":"590","PM":"508",
					"VC":"1-784","WS":"685","SM":"378","ST":"239","SA":"966","SN":"221","RS":"381","SC":"248","SL":"232",
					"SG":"65","SX":"1-721","SK":"421","SI":"386","SB":"677","SO":"252","ZA":"27","KR":"82","SS":"211",
					"ES":"34","LK":"94","SD":"249","SR":"597","SZ":"268","SE":"46","CH":"41","SY":"963","TW":"886",
					"TJ":"992","TZ":"255","TH":"66","TG":"228","TK":"690","TO":"676","TT":"1-868","TN":"216","TR":"90",
					"TM":"993","TC":"1-649","TV":"688","VI":"1-340","UG":"256","UA":"380","AE":"971","GB":"44","US":"1",
					"UY":"598","UZ":"998","VU":"678","VA":"379","VE":"58","VN":"84","WF":"681","EH":"212","YE":"967",
					"ZM":"260","ZW":"263"};
			},
			$t(param){
				let data = [];
				data[1] = 'Спасибо!';
				return data[param] ? data[param]:"";
			},
			async getCountry(){
                let res = await axios.post('/api/support/get_country_code');
                if(res && res.data && res.data.code){
					if(typeof this.geoPhoneMap()[res.data.code]!=='undefined '){
                        if(res.data.code == 'CS') res.data.code = 'US';
					    this.phone = '+'+this.geoPhoneMap()[res.data.code].toString();
					}
					else {
                        this.phone = '+1';
					}
				}
			}
		},
		watch: {
			name() {
				if(this.lastAttempt === true) this.validateInputName()
			},
			email() {
				if(this.lastAttempt === true) this.validateInputEmail()
			},
			/*siteUrl() {
				if(this.lastAttempt === true) this.validateInputSite()
			},*/
			/*phone(){
				if(this.lastAttempt === true) this.validateInputPhone()
			}*/
		},
		mounted () {
			this.getCountry();
		}
	}
</script>
<style lang="less" scoped>
	.content__form{
		-webkit-box-shadow: 0px 0px 30px 0px rgba(9,53,71,0.4);
		-moz-box-shadow: 0px 0px 30px 0px rgba(9,53,71,0.4);
		box-shadow: 0px 0px 30px 0px rgba(9,53,71,0.4);
		padding: 50px 50px 0 50px;
		background: #fff;
		width: 520px;
		border-radius: 30px;
		position: relative;
		h2{
			font-size: 25px;
			text-align: center;
			margin: 0;
		}
		h3{
			font-size: 16px;
			margin: 10px 0 15px 0;
			font-weight: 500;
		}
		div.form-group{
			position: relative;
			min-height: 62px;
			width: 100%;
			/deep/.s-group__content{
				/deep/.s-group__helper{
					margin: 2px 0 0 3px;
				}
			}

			/deep/.s-input{
				input{
					background: #eef1f9;
				}
			}
			/deep/.s-input-tel{
				width: 100%;
				height: 40px;
				min-width: 0;
				/deep/.s-select__header{
					height: 40px;
					background: #eef1f9;
				}
			}
			label{
				cursor: text;
				&:hover{
					cursor: text;
				}
				&:focus{
					cursor: text;
				}
				position: absolute;
				z-index: 2;
				top: 10px;
				left: 17px;
				color: #b0b5c0;
				font-size: 14px;
				/deep/span{
					color: red;
					font-size: 24px;
					position: absolute;
					margin-left: 3px;
				}
			}
		}
		button{
			height: 50px;
			width: 235px;
			background-color: #ff1f4a;
			border: none;
			color: #fff;
			font-size: 18px;
			border-radius: 5px;
			font-family: 'Raleway', sans-serif;
			line-height: 25px;
			display: block;
			margin: 0 auto;
			&:hover{
				cursor: pointer;
			}
			&:focus{
				outline: none;
			}
		}
		p{
			font-size: 12px;
			line-height: 18px;
			padding: 0 20px;
			text-align: center;
			font-weight: 500;
			a{
				color: #4b79bb;
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			}
		}
		.content-form__underline {
			position: absolute;
			border-bottom: 2px solid #eef1f9;
			width: 100%;
			left: 0;
		}
		.content__form--form-footer{
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 15px 0;
			position: relative;
			p{
				font-weight: 700;
				color: #093547;
				font-size: 18px;
				width: auto;
				/deep/span{
					color: #4b79bb;
				}
			}
			.content__form--form-footer__underline{
				position: absolute;
				border-bottom: 3px solid #ff1f4a;
				width: 60%;
				bottom: 0;
			}
		}
		h3{
			color: #093547;
			text-align: center;
		}
		/deep/.s-input{
			position: relative;
		}
		label{
			position: absolute;
		}
		/deep/ .s-group__helper__default{
			font-size: 12px;
			margin-top: -2px;
		}
	}
	/deep/.s-dialog-success-content__message{
		h3{
			font-size: 36px;
			font-weight: 700;
			font-family: 'Raleway', sans-serif;
			text-transform: uppercase;
			margin: 35px 0 25px 0;
			color: #2c3e50;
		}
		p{
			font-size: 18px;
			font-family: 'Raleway', sans-serif;
			color: #2c3e50;
		}
	}
	.privacy-terms-of-service{
		/deep/a{
			color: #4b79bb;
			text-decoration: none;
		}
	}
	@media (min-width: 2500px) {
		.content__form {
			padding: 30px 50px 0 50px;
		}
	}
	@media (min-width: 1200px) and (max-width: 1600px){
		.content__form {
			padding: 30px 50px 0 50px;
		}
	}
	@media (max-width: 992px) {
		.content__form{
			width: 400px;
			h3{
				font-size: 14px;
			}
			p{
				padding: 0 10px;
			}
			.content__form--form-footer{
				padding: 5px 0;
			}
		}
	}
	@media (max-width: 480px) {
		.content__form{
			width: 340px;
			padding: 30px 30px 0 30px;
			h3{
				font-size: 14px;
			}
			p{
				padding: 0 10px;
			}
			.content__form--form-footer{
				padding: 5px 0;
			}
		}
	}
	@media (max-width: 360px) {
		.content__form{
			width: 320px;
			padding: 20px 20px 0 20px;
		}
	}
</style>
