<template>
	<div v-if="ll" class="container-fluid no-gutter reseller">
		<Title :ll="ll" />
		<MainForm :ll="ll" />
	</div>
</template>
<script>
	import Vue from 'vue'
	import '@semalt/frontend/dist/semalt-frontend.css';
	import Semalt from '@semalt/frontend';
	Vue.use(Semalt);

	import Title from '../components/rp/Title'
	import MainForm from '../components/rp/MainForm'
	import axios from 'axios'


	export default {
		name: 'BacklinksAgencies',
		data: function() {
			return  {
				ll: null,
			}
		},
		props: {
			//msg: String
		},
		components: {
			Title,
			MainForm
		},
		/*watch:{
			lang(to, from){
				this.getll();
			},
		},*/
		methods: {
			async init(){
				this.route();
				await this.getll();
			},
			async getll(){
				let res = await axios.post('/api/main/get', {datall:{"lang": this.lang,"controller": ["backlinks-for-seo-agencies"]} });
				if(res && res.data) {
					if(res.data.datall){
						this.ll = res.data.datall;
					}
				}
			},
			route(){
				/* для теста временно закоментировано
				if(this.$route.params.lang){
					if(this.$root.languages.includes(this.$route.params.lang)){
						if(this.$route.params.lang != this.$root.lang) {
							this.$root.lang = this.$route.params.lang;
						}
					}
					else{
						this.$router.push('/en/Backlink-for-SEO-Agencies');
						this.$router.push('/Backlink-for-SEO-Agencies');
						this.$root.lang = 'en';
					}
				}
				*/
			}
		},
		computed:{
			lang(){
				return this.$root.lang;
			}
		},
		mounted(){
			this.init();
		},
	}
</script>
<style src="../assets/styles/index.less" lang="less" scoped></style>
