/* eslint-disable */
export default {
	data: function () {
		return {
			test: {abc:123},
			//ll: {}
		}
	},
	methods:{
		df(date,type) {
			type = type||0;
			let dd = new Date(date);
			let ll = this.ll;
			if(typeof ll.i3581=='undefined'  ) return dd.toString();
			let monthShort = [ ll.i3581, ll.i3582, ll.i3583, ll.i3584, ll.i3585, ll.i3586, ll.i3587, ll.i3588, ll.i3589, ll.i3590, ll.i3591, ll.i3592];

			let str_week = '', str_en=', ';
			let str_month = monthShort[dd.getMonth()];
			let str_year = dd.getFullYear();
			if(type==1){
				let month = [ll.i3601,ll.i3602,ll.i3603,ll.i3604,ll.i3605,ll.i3606,ll.i3607,ll.i3608,ll.i3609,ll.i3610,ll.i3611,ll.i3612];
				str_month = month[dd.getMonth()];
			}
			else if(type==2){
				let week = [ll.i4009,ll.i4010,ll.i4011,ll.i4012,ll.i4013,ll.i4014,ll.i4015];
				str_week = week[dd.getDay()]+', ';
			}
			else if(type==3){
				let weekShort = [ll.i4016,ll.i4017,ll.i4018,ll.i4019,ll.i4020,ll.i4021,ll.i4022];
				str_week = weekShort[dd.getDay()]+', ';
			}
			else if(type==4){
				str_year = '';
				str_en = '';
			}
			let result;
			switch (this.$root.lang){
				case 'en': result = str_week + str_month +" "+dd.getDate() + str_en + str_year; break;
				case 'zh': result = str_week + str_year+'年 '+ (parseInt(dd.getMonth(),10)+1) +'月 '+dd.getDate()+'日'; break;
				default: result = str_week + dd.getDate()+" "+ str_month +" "+str_year;
			}
			return result.toString();
		},
		generateLinkPayMix(data){
			//console.log(data);
			data.idu = 0;
			data.email = '';
			data.username = '';
			if(data.manager.u_id) data.idu = data.manager.u_id;
			if(data.manager.u_email) data.email = data.manager.u_email;
			if(data.manager.u_username) data.username = data.manager.u_username;
			let priceVal = parseInt(data.price);;

			let params = [
				'referrer='+data.idu,
				'contact_lname=%20',
				'tags=total='+ priceVal*100*(data.project&&data.project.length>1?data.project.length:1),
			];
			if(data.email != '' )  params.push('contact_email='+encodeURIComponent(data.email));
			if(data.username != '' )  params.push('contact_fname='+encodeURIComponent(data.username));
			if(data.project && data.project[0] && data.project[0]>0) { params[0] += '*' + data.project.join("*");}
			else data.project = [0];
			//console.log(params);

			if(data.manager.id){ params[0] += ';m='+data.manager.id;}
			if(data.button){ params[0] += ';f='+data.button;}
			if(data.idu==0) { params[0] += ';o=1';}

			let linkNamePack = 'autosearchengineoptimization'; // this.priceObj[data.package][data.packageKey].linkfs;
			if(priceVal<10) linkNamePack = 'autosearchengineoptimizationtrial';
			if(data.package && data.package=='fullseo') linkNamePack = 'semaltseoservices';

			let paramStr = params.join('&');

			let fastspringLink = 'https://sites.fastspring.com/semalt/checkout/'+linkNamePack+'?' + paramStr;
			let nextUrl = 'https://semalt.com/handlers/subscribe_click.php?url=' + encodeURIComponent(fastspringLink) + '&btn='+data.button+ (data.project?'&chosen='+data.project[0]:'');
			//console.log(data);
			//console.log(nextUrl);
			return nextUrl;
		},
	}
}
