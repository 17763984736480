<template>
	<div class="contaier-fluid no-gutter">
		<div class="container">
			<div class="container--left-part">
				<ul>
					<li class="hh2">{{ll.i6843}}
						<br><span>&mdash; {{ll.i6844}}</span>
						<br><span>&mdash; {{ll.i6845}}</span>
						<br><span>&mdash; {{ll.i6846}}</span>
						<br><span>&mdash; {{ll.i6847}}</span>
						<br><span>&mdash; {{ll.i6848}}</span>
					</li>
					<!--<li>{{ll.i6823}} <span>{{ll.i6824}}</span></li>
					<li>{{ll.i6825}} <span>{{ll.i6826}}</span></li>-->
				</ul>
			</div>
			<div class="container--right-part">
				<Form :ll="ll" />
				<!--<p>Reseller Program offers fast and quality result for an affordable price for the clients of digital agencies looking for a SEO partner.</p>-->
			</div>
		</div>
		<img class="hleft" src="/ims/header-img.png" alt="MainForm bottom img" />
	</div>
</template>

<script>
	import Form from './Form'
	export default {
		name: "MainForm",
		props: {
			ll: Object
		},
		components: {
			Form
		}
	}
</script>

<style lang="less" scoped>
	.hleft{
		left: 0;
	}
	.container{
		display: flex;
		justify-content: space-around;
		.container--left-part {
			margin-top: 35px;
			width: 45%;
			text-align: left;
			position: relative;
			z-index: 1;

			ul{
				li{
					font-size: 16px;
					line-height: 20px;
					color: #4b79bb;
					margin: 20px;
					font-weight: 700;
					/deep/span{
						color: #093547;
						font-weight: 400;
					}
				}
				li.hh2{
					line-height: 30px;
				}
			}
		}
		.container--right-part{
			width: 520px;
			margin: 20px 0 70px 0;
		}
	}
	.contaier-fluid {
		position: relative;
		img{
			position: relative;
			left: 0;
			margin-top: -240px;
			width: 43%;
			max-width: 640px;
			max-height: 340px;
			display: block;
		}
	}
	@media (max-width: 992px) {
		.container{
			.container--right-part{
				width: 400px;
			}
		}
	}
	@media (max-width: 768px) {
		.container{
			flex-direction: column;
			/*flex-direction: column-reverse;*/
			align-items: center;
			.container--left-part{
				margin-top: 0;
				width: 80%;
			}
			.container--right-part{
				margin: 20px 0 30px 0;
			}
		}
		.contaier-fluid{
			img{
				margin-top: 0;
				position: static;
				width: 50%;
			}
		}
	}
	@media (max-width: 480px){
		.container{
			.container--right-part{
				width: 340px;
			}
			.container--left-part{
				margin-top: 0;
				width: 100%;
				ul{
					padding-left: 15px;
				}
			}
		}
		.contaier-fluid{
			img{
				margin-top: 0;
				position: static;
				width: 70%;
			}
		}
	}
	@media (max-width: 360px){
		.container{
			.container--right-part{
				width: 320px;
			}
			.container--left-part{
				margin-top: 0;
				width: 100%;
			}
		}
	}
</style>
