<template>
	<div class="title container">
		<h1 data-ll="6820">{{ll.i6820}}</h1>
		<h2 data-ll="6821">{{ll.i6821}}</h2>
	</div>
</template>

<script>
	/* eslint-disable */
	import mixin from '../../assets/js/mixin'
	export default {
		name: "Title",
		props: {
			msg: String,
			ll: Object
		},
		components: {
			//mHeaderFlag
		},
		mounted(){

		}
	}
</script>

<style lang="less" scoped>
	.title{
		text-align: center;
		padding: 0 0 30px 0;
		/deep/h1{
			font-weight: 800;
			font-size: 52px;
			line-height: 52px;
		}
		/deep/h2{
			font-size: 36px;
			line-height: 42px;
		}
	}
	/deep/.s-header-flag__inner:hover{
		cursor: pointer;
	}
	@media (max-width: 992px) {
		.title{
			padding: 0 0 10px 0;
			/deep/h1{
				font-weight: 800;
				font-size: 46px;
				line-height: 46px;
			}
			/deep/h2{
				font-size: 30px;
				line-height: 32px;
			}
		}
		/deep/.s-header-flag{
			margin: 0 auto;
		}
	}
	@media (min-width: 2500px) {
		div .title {
			padding-bottom: 10px;
			/deep/h1{
				margin-top: -25px;
			}
		}
	}
	@media (min-width: 1200px) and (max-width: 1600px){
		div .title {
			padding-bottom: 10px;
			/deep/h1{
				/*margin-top: -25px;*/
			}
		}
	}
	@media (max-width: 768px) {
		.title{
			width: 90%;
		}
	}
	@media (max-width: 576px) {
		.title{
			/deep/h1{
				font-weight: 800;
				font-size: 36px;
				line-height: 40px;
			}
			/deep/h2{
				font-size: 24px;
				line-height: 28px;
			}
		}
	}

</style>


